import React, { useRef, useEffect } from 'react'
import { navigate } from 'gatsby'
import styled from 'styled-components'
import { toCssPrefix, breakpoints, getOrderPaymentStatus, useShoppingCart, useAuth } from '@ecommerce/shared'
import lottie from 'lottie-web'
import { Helmet } from 'react-helmet'
import NavBarStaticPage from '../../components/NavBar/NavBarStaticPage'
import animationData from '../../mocks/processing_payment_animation.json'
import { PgPageProps } from '../../types/PgPages'
import { sendPageViewEvent } from '../../utils/events'

const { cssPrefix, toPrefix } = toCssPrefix('PaymentProcessingPage__')
const Wrapper = styled.div`
  .${cssPrefix} {
    &content {
      padding: 152px 50px;
      text-align: center;
      max-width: 768px;
      margin: auto;
    }

    &title {
      font-size: 18px;
      max-width: 190px;
      margin: auto;
    }

    &animation-wrapper {
      width: 203px;
      height: 203px;
      margin: 40px auto 25px;
    }

    &text {
      font-size: 18px;
      line-height: 22px;
      max-width: 274px;
      margin: auto;
    }
  }

  @media screen and (${breakpoints.desktop.min}) {
    background: ${({ theme }) => theme.colors.grey};
    min-height: 100vh;
    padding-top: 140px;

    .${cssPrefix} {
      &content {
        background: ${({ theme }) => theme.colors.white};
        padding: 78px;
        height: 700px;
        border-radius: ${({ theme }) => theme.borderRadius};
        box-shadow: ${({ theme }) => theme.boxShadow.lvlOne};
      }
    }
  }
`

const PaymentProcessing = ({ location }: PgPageProps) => {
  const animationContainer = useRef<HTMLDivElement>(null)
  const { getConnectifCart } = useShoppingCart()
  const { getEntityInfo } = useAuth()

  useEffect(() => {
    const entityInfo = getEntityInfo()
    const cart = getConnectifCart()
    sendPageViewEvent({ cart, entityInfo, title: 'Pago en proceso' })
  }, [])

  const orderId = new URLSearchParams(location.search).get('orderId')
  const paymentPendingPage = `/payment/pending?req_reference_number=${orderId}`
  const paymentCancelledPage = `/payment/cancelled?req_reference_number=${orderId}`

  const getUrlToRedirect = (status: string) => {
    if (status === 'Done') return paymentPendingPage

    return paymentCancelledPage
  }

  const redirectTo = (path: string) => {
    return navigate(path)
  }

  const fetchStatus = async () => {
    try {
      if (!orderId) return

      const { status } = await getOrderPaymentStatus(orderId)

      await redirectTo(getUrlToRedirect(status))
    } catch (error) {
      await redirectTo(paymentCancelledPage)
    }
  }

  useEffect(() => {
    fetchStatus()

    if (animationContainer?.current) {
      lottie.loadAnimation({
        container: animationContainer.current,
        renderer: 'svg',
        loop: true,
        autoplay: true,
        animationData,
      })
    }
  }, [])

  return (
    <Wrapper className={cssPrefix}>
      <Helmet>
        <title>Estamos procesando tu pago... | miCoca-Cola.cl</title>
      </Helmet>
      <NavBarStaticPage className={toPrefix('navbar')} />
      <div className={toPrefix('content')}>
        <h1 className={toPrefix('title')}>Estamos procesando tu pago...</h1>
        <div ref={animationContainer} className={toPrefix('animation-wrapper')} />
        <p className={toPrefix('text')}>Disfruta de todos los beneficios que tenemos para ti en micoca-cola.cl</p>
      </div>
    </Wrapper>
  )
}

export default PaymentProcessing
